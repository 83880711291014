export const makeQueryParamsHelper = (
  params: object,
): { [key: string]: string } => {
  if (!params) return null
  return Object.entries(params).reduce(
    (obj, [key, value]) => {
      if (value) obj[key] = value;

      return obj;
    },
    {} as { [key: string]: string },
  );
};
